import React, { useState, useEffect } from 'react';

import { months, getMonth, toShortFormat, toShorterFormat } from '../../functions/Datetimes';

import RightArrow from '../../assets/RightArrow.png';

/**
 * legend:
 * default
 * outmonth
 * inmonth
 * outavailable
 * inavailable
 * @param {*} props 
 * @returns 
 */
const uniformStyle = {
    width: '32rem',
    height: '32rem',
    borderRadius: '50%'
}
const styles = {
    default: {
        backgroundColor: '#0000',
        color: 'var(--primary)',
        border: '2rem solid #0000'
    },
    outMonth: {
        backgroundColor: '#0000',
        color: 'var(--primary-30)',
        border: '2rem solid #0000'
    },
    available: {
        backgroundColor: 'var(--white)',
        color: 'var(--primary)',
        border: '2rem solid var(--white)'
    },
    outLocation: {
        backgroundColor: '#0000',
        color: 'var(--primary)',
        border: '2rem dashed var(--primary)'
    },
    availableHover: {
        backgroundColor: 'var(--primary)',
        color: 'var(--white)',
        border: '2rem solid var(--primary)'
    },
    outLocationHover: {
        backgroundColor: 'var(--primary)',
        color: 'var(--white)',
        border: '2rem dashed var(--white)'
    },
    selected: {
        backgroundColor: 'var(--primary)',
        color: 'var(--white)',
        border: '2rem solid var(--primary)'
    },
    outLocationSelected: {
        backgroundColor: 'var(--primary)',
        color: 'var(--white)',
        border: '2rem dashed var(--white)'
    }
}

function CalendarDate(props) {
    const { id, label, outMonth, available, outLocation, selected, handleOnClick } = props;
    const [hover, setHover] = useState(false);

    // console.log('check: ' + label + ': ' + (outLocation ? 'OUT' : 'IN'));

    const style = outMonth ? styles.outMonth :
        (selected ? styles.selected :
            (available ? (hover ? styles.availableHover : styles.available) :
                (outLocation ? styles.outLocation : styles.default)));

    return (<div className={'frame center ' + (available ? 'hover-pointer' : '')}
        style={{
            ...style,
            ...uniformStyle
        }}
        onClick={handleOnClick ? handleOnClick : () => { }}
        onMouseEnter={() => {
            setHover(true);
        }}
        onMouseLeave={() => {
            setHover(false);
        }}><p>{label}</p></div>);
}
function Calendar(props) {
    const { availableDates, otherAvailable, state, setState, confirm, cancel, close, select } = props;
    const [month, setMonth] = useState(0);
    const [selected, setSelected] = useState(state);
    const [hovered, setHovered] = useState(null);

    // console.log('other: ' + JSON.stringify(otherAvailable, null, 4));

    const monthsOfYear = [];
    for (const av of availableDates) {
        const day = new Date(av.date);
        // console.log(day);
        const month = day.getMonth();
        const year = day.getFullYear();
        if (!monthsOfYear.find((m) => { return m[0] === getMonth(month) })) {
            // console.log('push');
            monthsOfYear.push([getMonth(month), year]);
        }
    }

    const [calendarMonths, setCalendarMonths] = useState(monthsOfYear);

    // console.log(JSON.stringify(calendarMonths, null, 4));
    // console.log('props: ' + JSON.stringify(props.availableDates, null, 4));

    //Get calendar month
    let highlightedMonth = calendarMonths[month];
    if (highlightedMonth === undefined) {
        highlightedMonth = ['November', 2023];
    }
    let startDay = new Date(highlightedMonth[0] + ' 1, ' + highlightedMonth[1]).getDay();
    let daysOfMonth = [];
    const numDaysLastMonth = new Date(highlightedMonth[1], months.indexOf(highlightedMonth[0]), 0).getDate() + 1;
    for (var i = 0; i < startDay; i++) {
        daysOfMonth.push('.' + (numDaysLastMonth - (startDay - i)));
    }
    const numDaysThisMonth = new Date(highlightedMonth[1], months.indexOf(highlightedMonth[0]) + 1, 0).getDate();
    // console.log(getMonth(months.indexOf(highlightedMonth[0])) + ': ' + numDaysThisMonth);
    for (var i = 0; i < numDaysThisMonth; i++) {
        daysOfMonth.push(' ' + (i + 1));
    }
    var index = 0;
    const domCap = 6 * 7 - daysOfMonth.length + 1;
    // console.log('cap: ' + domCap);
    while (index < domCap) {
        daysOfMonth.push('.' + (index + 1));
        index++;
    }

    // console.log('daysOfMonth: ' + daysOfMonth);

    // console.log('daysOfMonth' + daysOfMonth);

    const [calendarDays, setCalendarDays] = useState(daysOfMonth);

    useEffect(() => {
        // console.log('calendarMonths: ' + calendarMonths + ' month: ' + month);
        let highlightedMonth = calendarMonths[month];
        if (highlightedMonth === undefined) {
            highlightedMonth = ['November', 2023];
        }
        let startDay = new Date(highlightedMonth[0] + ' 1, ' + highlightedMonth[1]).getDay();
        let daysOfMonth = [];
        const numDaysLastMonth = new Date(highlightedMonth[1], months.indexOf(highlightedMonth[0]), 0).getDate() + 1;
        for (var i = 0; i < startDay; i++) {
            daysOfMonth.push('.' + (numDaysLastMonth - (startDay - i)));
        }
        const numDaysThisMonth = new Date(highlightedMonth[1], months.indexOf(highlightedMonth[0]) + 1, 0).getDate();
        // console.log(getMonth(months.indexOf(highlightedMonth[0])) + ': ' + numDaysThisMonth);
        for (var i = 0; i < numDaysThisMonth; i++) {
            daysOfMonth.push(' ' + (i + 1));
        }
        var index = 0;
        const domCap = 6 * 7 - daysOfMonth.length + 1;
        // console.log('cap: ' + domCap);
        while (index < domCap) {
            daysOfMonth.push('.' + (index + 1));
            index++;
        }

        setCalendarDays(daysOfMonth);
    }, [month]);

    let weekFrames = [];
    var availableIndex = 0;
    for (const av of availableDates) {
        // console.log(availableIndex + ': av.year: ' + av.date.getFullYear() + ', calendarYear: ' + new Date(calendarMonths[month][0] + ' 1, ' + calendarMonths[month][1]).getFullYear());
        // console.log(availableIndex + ': av.month: ' + av.date.getMonth() + ', calendarMonth: ' + new Date(calendarMonths[month][0] + ' 1, ' + calendarMonths[month][1]).getMonth());
        if (av.date.getFullYear() < new Date(calendarMonths[month][0] + ' 1, ' + calendarMonths[month][1]).getFullYear() ||
            (av.date.getFullYear() === new Date(calendarMonths[month][0] + ' 1, ' + calendarMonths[month][1]).getFullYear() && av.date.getMonth() < new Date(calendarMonths[month][0] + ' 1, ' + calendarMonths[month][1]).getMonth())) {
            availableIndex++;
        }
    }
    // calendarMonths.reduce((acc, m) => {return acc + new Date(m[1], getMonth(m[0]), 0).getDate()}, 0) - new Date(calendarMonths[0][1], getMonth(calendarMonths[0][0]), 0).getDate();
    // console.log('availableIndex: ' + availableIndex);
    // console.log('props.available: ' + JSON.stringify(availableDates, null, 4));
    let id;
    for (var i = 0; i < 6; i++) {
        let dateFrames = [];
        for (var j = 0; j < 7; j++) {
            // console.log('index: ' + availableIndex);
            id = i * 7 + j;
            // console.log(id + ': ' + calendarDays[id]);
            if (calendarDays[id].startsWith('.')) {
                dateFrames.push(
                    <CalendarDate outMonth label={calendarDays[id].substring(1)} />
                );
                continue;
            }
            const thisDateString = calendarMonths[month][0] + ' ' + calendarDays[id].substring(1) + ', ' + calendarMonths[month][1];
            // console.log('thisdatestring: ' + thisDateString);
            const thisDate = new Date(thisDateString);
            // console.log('thisDate date: ' + thisDate.getDate() + ', thisDate date: ' + thisDate.getMonth())
            // console.log('index: ' + availableIndex + 'props.available[index]: ' + JSON.stringify(availableDates[availableIndex]));
            // console.log('availableIndex date: '+props.available[availableIndex].date.getDate() + ', availableIndex date: '+props.available[availableIndex].date.getMonth());
            const available = (availableIndex < availableDates.length &&
                thisDate.getDate() === availableDates[availableIndex].date.getDate() &&
                thisDate.getMonth() === availableDates[availableIndex].date.getMonth()
            );
            // console.log('avInd: '+availableIndex+': '+JSON.stringify(props.available[availableIndex]));
            // if(props.available[availableIndex].date) {
            //     console.log('propsddate: ' + props.available[availableIndex].date);
            //     console.log('thisDate: ' + thisDate.getDate() + ', propsDate: ' + props.available[availableIndex].date.getDate());
            //     console.log('thisMonth: ' + thisDate.getMonth() + ', propsMonth: ' + props.available[availableIndex].date.getMonth());
            // }
            const availableId = available ? availableDates[availableIndex].id : -1;
            if (available) {
                // console.log('HERE');
                availableIndex++;
            }
            dateFrames.push(
                <CalendarDate handleOnClick={(available ? () => {
                    // console.log('CLICKED AVID: ' + availableId + ': ' + JSON.stringify(availableDates.find((a) => a.id === availableId)));
                    setState(availableId);
                    setSelected(availableId);
                    if (select) select();
                } : () => { })} label={calendarDays[id].substring(1)}
                    selected={state === availableId}
                    available={available}
                    outLocation={otherAvailable === undefined ? false : otherAvailable.find((a) => {
                        a.date.setHours(0, 0, 0, 0);
                        // console.log('other: ' + a.date.toDateString() + ' =? ' + thisDate.toDateString());
                        // if (a.date.toDateString() === thisDate.toDateString()) console.log('HERE');
                        return a.date.toDateString() === thisDate.toDateString()
                    })}
                ></CalendarDate>
            );
        }
        let weekFrame = <div key={i} className='frame justify-between' style={{ gap: '12rem' }}>
            {dateFrames}
        </div>
        if ((id > 7 && !calendarDays[id - 7].startsWith('.')) || (id < 7 && !calendarDays[id].startsWith('.'))) weekFrames.push(weekFrame);
    }

    return (
        <div className='frame column align-start' style={{ gap: '18rem', padding: '0 8rem 8rem', borderRadius: '24rem' }}>
            <div className='frame center stretch' style={{ gap: '8rem' }}>
                <p className='frame flex-100 font-32'>{(calendarMonths[month] ? calendarMonths[month].join(' ') : '')}</p>
                {month > 0 && <img className='hover-pointer' src={RightArrow}
                    onClick={() => { setMonth(month - 1) }}></img>}
                {month < calendarMonths.length - 1 && <img className='hover-pointer' src={RightArrow}
                    onClick={() => {
                        // console.log('calmonthslength: ' + calendarMonths.length);
                        setMonth(month + 1)
                    }}></img>}
            </div>
            <div className='frame column' style={{ gap: '8rem' }}>
                <div className='frame justify-between font-12 bold' style={{ gap: 'auto', padding: '0 14rem' }}>
                    <p>S</p>
                    <p>M</p>
                    <p>T</p>
                    <p>W</p>
                    <p>Th</p>
                    <p>F</p>
                    <p>S</p>
                </div>
                <div className='frame column font-12' style={{ gap: '8rem' }}>
                    {weekFrames}
                </div>
            </div>
            <div className='frame center row align-items-start' style={{ gap: '8rem' }}>
                <div className='frame center primary solid hover-pointer' style={{ padding: '8rem 16rem', borderRadius: '16rem' }}
                    onClick={() => {
                        if (props.confirm) props.confirm(selected);
                        if (props.close) props.close();
                    }}>
                    <p className='bold font-18'>Confirm</p>
                </div>
                {props.cancel && <div className='frame center primary inverted hover-pointer' style={{ padding: '8rem 16rem', borderRadius: '16rem' }}
                    onClick={() => {
                        if (props.cancel) props.cancel();
                        if (props.close) props.close();
                    }}>
                    <p className='bold font-18'>Cancel</p>
                </div>}
            </div>
            <div className='frame column' style={{ gap: '4rem' }}>
                <p className='bold font-16 primary'>Legend</p>
                <div className='frame row align-center align-start' style={{ gap: '8rem' }}>
                    <div className='frame center' style={{
                        width: '28rem',
                        height: '28rem',
                        borderRadius: '50%',
                        ...styles.available
                    }}>
                        <p className='font-16'>12</p>
                    </div>
                    <p className='font-18 primary'>is available</p>
                </div>
                <div className='frame row align-center align-start' style={{ gap: '8rem' }}>
                    <div className='frame center' style={{
                        width: '28rem',
                        height: '28rem',
                        borderRadius: '50%',
                        ...styles.outLocation
                    }}>
                        <p className='font-16'>12</p>
                    </div>
                    <p className='font-18 primary'>is available at the other location</p>
                </div>
                <div className='frame row align-center align-start' style={{ gap: '8rem' }}>
                    <div className='frame center' style={{
                        width: '28rem',
                        height: '28rem',
                        borderRadius: '50%',
                        ...styles.default
                    }}>
                        <p className='font-16'>12</p>
                    </div>
                    <p className='font-18 primary'>is not available</p>
                </div>
            </div>
        </div>
    );
}

export default Calendar;