import React, { useEffect, useState, useContext } from 'react';

import ScheduleContext from '../contexts/ScheduleContext';

import Selector from '../components/Selector';
import PillOption from '../styles/PillOption';
import Calendar from '../components/Calendar';
import { toShortFormat, toShorterFormat, toReadableTime, toLongTime } from '../../functions/Datetimes';

import CalendarIcon from '../../assets/CalendarIcon.svg';
import DownArrow from '../../assets/DownArrow.svg';

/* location formats
 * SAN LEANDRO, CA
 * SAN LEANDRO
 * SL, CA
 * SL
 */

//split times
function divideTimes(startTime, endTime) {
    const times = [];
    const interval = 15;
    const start = new Date(startTime);
    const end = new Date(endTime);

    var current = start;
    var id = 0;
    while (current < end) {
        const time = {
            timeID: id,
            time: {
                hour: current.getHours(),
                minute: current.getMinutes()
            }
        };
        // console.log('time: ' + JSON.stringify(time, null, 4));
        times.push(time);

        current.setMinutes(current.getMinutes() + interval);
        id++;
    }

    return times;
}

//readables
function getDate(availabilities, location, date) {
    return availabilities[location]?.dates[date] ? toShorterFormat(toShortFormat(availabilities[location].dates[date].date)) : 'DAY, MON DT';
}

function getShortTime(availabilities, location, date, time) {
    return availabilities[location]?.dates[date]?.times[time] ? toReadableTime(availabilities[location].dates[date].times[time].time) : '00:00';
}

function getLongTime(availabilities, location, date, time) {
    return availabilities[location]?.dates[date]?.times[time] ? toLongTime(availabilities[location].dates[date].times[time].time) : '00:00 - 00:00';
}

function addState(loc) {
    return loc + ', CA';
}

//maps
function getDateChoices(availabilities, location) {
    // console.log('date choice: ' + availabilities[location]?.location);
    // console.log('date choice : ' + JSON.stringify(availabilities[location]?.dates));
    const choices = availabilities[location]?.dates.map((d, i) => {
        // console.log('date choice: ' + JSON.stringify(d));
        return {
            id: i,
            date: d.date,
            name: toShorterFormat(toShortFormat(d.date))
        };
    });
    // console.log('date choices: '+JSON.stringify(choices, null, 4));
    return choices;
}

function getTimeChoices(availabilities, location, date) {
    const choices = availabilities[location]?.dates[date]?.times.map((t, i) => {
        // console.log('time: ' + JSON.stringify(t));
        return {
            id: i,
            name: toLongTime(t.time)
        };
    });
    // console.log('time choices: ' + JSON.stringify(choices, null, 4));
    return choices;
}

function getLocationChoices(availabilities) {
    const choices = availabilities.map((l) => {
        return {
            id: l.locationID,
            name: addState(l.location)
        }
    });
    // console.log('location choices: ' + JSON.stringify(choices, null, 4));
    return choices;
}

function Scheduler(props) {
    const { schedule, setSchedule, location, setLocation, date, setDate, time, setTime } = useContext(ScheduleContext);
    const scheduleCookie = schedule;
    // console.log('scheduleCookie: ' + JSON.stringify(scheduleCookie));
    const [view, setView] = useState(scheduleCookie ? 'quick' : 'home');

    const [availabilities, setAvailabilities] = useState([]);

    const [isOpen, setIsOpen] = useState(true);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/.netlify/functions/scheduler')
            .then((res) => res.json())
            .then((data) => {
                // console.log('DATA: ' + JSON.stringify(data));

                let availabilities = [];
                for (let i = 0; i < data.length; i++) {
                    let location = availabilities.find((a) => {
                        // console.log(a.location + ' =? ' + data[i].location);
                        return a.location === data[i].location;
                    });

                    if (!location) {
                        location = {
                            locationID: availabilities.length,
                            location: data[i].location,
                            dates: []
                        };
                        availabilities.push(location);
                    }
                    // console.log('location: '+JSON.stringify(location, null, 4));

                    //get 15 min intervals
                    const startDate = new Date(data[i].start_time);
                    const endDate = new Date(data[i].end_time);
                    // console.log('startDate: ' + startDate);

                    location.dates.push({
                        dateID: i,
                        date: startDate,
                        times: divideTimes(startDate, endDate)
                    });
                }

                // console.log('availabilities' + JSON.stringify(availabilities));
                setAvailabilities(availabilities);

                if (schedule) {
                    const locationID = availabilities.findIndex((av) => {
                        return av.location === schedule.location;
                    });
                    if (locationID < 0) {
                        // setError('Unable to retrieve your scheduled location.');
                        console.log('Unable to retrieve your scheduled location.');
                        setLocation(0);
                        setDate(0);
                        setTime(0);
                        setSchedule(null);
                        return;
                    }
                    setLocation(locationID);
                    //check if date still exists
                    const dateID = availabilities[locationID].dates.findIndex((av) => {
                        // console.log(toShorterFormat(toShortFormat(av.date)) + ' comp ' + scheduleCookie.date)
                        return toShorterFormat(toShortFormat(av.date)) === schedule.date;
                    });
                    if (dateID < 0) {
                        // setError('Unable to retrieve your scheduled date.');
                        console.log('Unable to retrieve your scheduled date.');
                        setDate(0);
                        setTime(0);
                        setSchedule(null);
                        return;
                    }
                    setDate(dateID);
                    //check if time still exists
                    const timeID = availabilities[locationID].dates[dateID].times.findIndex((av) => {
                        // console.log(toLongTime(av.time) + ' comp ' + scheduleCookie.time)
                        return toLongTime(av.time) === schedule.time;
                    });
                    if (timeID < 0) {
                        // setError('Unable to retrieve your scheduled time.');
                        console.log('Unable to retrieve your scheduled time.');
                        setTime(0);
                        setSchedule(null);
                        return;
                    }
                    setTime(timeID);
                }
            });
    }, []);

    const locationLabel = addState(availabilities[location]?.location);
    const dateLabel = getDate(availabilities, location, date);
    const timeLabel = getShortTime(availabilities, location, date, time);

    const DatetimePill = <div className='frame row hover-pointer' style={{ gap: '12rem', padding: '8rem 16rem', borderRadius: '32rem', border: '2rem solid var(--light-grey)' }}>
        <div className='frame row' style={{ gap: '8rem', padding: '0' }}
            onClick={() => {
                if (view.startsWith('expanded')) {
                    if (!view.includes('calendar')) setView('expanded calendar');
                    else setView('expanded');
                }
                else if (view !== 'quick calendar') setView('quick calendar');
                else setView('quick');
            }}>
            <img style={{ padding: '1.5rem 0 0', width: '20rem', height: '20rem' }} src={CalendarIcon}></img>
            <p className='font-20'>{dateLabel}</p>
        </div>
        <div className='column-divider-1' style={{ backgroundColor: 'var(--primary)' }}></div>
        <p className='font-20'
            onClick={() => {
                if (view.startsWith('expanded')) {
                    if (!view.includes('times')) setView('expanded times');
                    else setView('expanded');
                }
                else if (view !== 'quick times') setView('quick times');
                else setView('quick');
            }}>{timeLabel}</p>
    </div>;
    const LocationPill = availabilities.length > 1 ?
    <div className='frame row center hover-pointer' style={{ gap: '1rem', padding: '8rem 16rem', borderRadius: '32rem', border: '2rem solid var(--light-grey)' }}
        onClick={() => {
            if (view !== 'quick location') setView('quick location');
            else setView('quick');
        }}>
        <p className='font-20'>{locationLabel}</p>
        <img style={{ width: '16rem', height: '16rem', transform: (view === 'quick location' ? 'rotate(180deg)' : '') }} src={DownArrow}></img>
    </div>:
    <div className='frame row center' style={{ gap: '1rem', padding: '8rem 16rem', borderRadius: '32rem' }}>
        <p className='font-24'>{locationLabel}</p>
    </div>
    ;

    return (
        <div className='frame align-end column align-self-end absolute transparent' style={{ top: '100%', right: '0', zIndex: '100', gap: '8rem', padding: '0' }}>
            {view === 'home' ? <div className='frame row align-center justify-around left-under-tab true-white' style={{ gap: '12rem', padding: '12rem 24rem' }}>
                <p className='font-24 primary semi-bold'>Schedule with us!</p>
                <div className='frame primary inverted hover-pointer' style={{ gap: '12rem', padding: '12rem 24rem', borderRadius: '36rem' }} onClick={() => { setView('quick'); }}>
                    <p className='font-20'>Start Scheduling</p>
                </div>
            </div>
                : <div className='frame row align-center justify-around left-under-tab true-white' style={{ gap: '12rem', padding: '12rem 24rem' }}>
                    <div className='frame center hover-pointer' onClick={() => setIsOpen(!isOpen)}>
                        <img src={DownArrow} style={{ transform: isOpen ? 'rotate(270deg)' : 'rotate(90deg)', width: '32rem', height: '32rem' }} />
                    </div>
                    {isOpen &&
                        <>
                            {schedule ? <p className='font-20 primary bold hover-pointer' onClick={() => setIsOpen(!isOpen)}>Schedule Reserved!</p> : <p className='font-20 primary bold hover-pointer' onClick={() => setIsOpen(!isOpen)}>Schedule for Pickup!</p>}
                            {LocationPill}
                            {DatetimePill}
                        </>
                    }
                </div>
            }
            {(view.includes('location') || view.includes('calendar') || view.includes('times')) && <div className='frame row align-center justify-around true-white absolute left-under-tab' style={{ gap: '12rem', padding: '12rem 24rem', top: '100%', right: '0', maxWidth: '100%' }}>
                {view.includes('location') && <div className='frame'>
                    <Selector options={getLocationChoices(availabilities)} state={location} setState={setLocation} optionStyles={PillOption()} select={() => {
                        setView('quick calendar');
                        setDate(0);
                    }} />
                </div>}
                {view.includes('calendar') && <Calendar
                    select={() => {
                        setTime(0);
                    }}
                    confirm={(id) => {
                        setSchedule({
                            locID: location,
                            location: availabilities[location].location,
                            dateID: date,
                            date: getDate(availabilities, location, date),
                            timeID: time,
                            time: getLongTime(availabilities, location, date, time)
                        });
                        setDate(id);
                        if (view.startsWith('quick')) setView('quick times');
                        else setView('expanded times');
                    }}
                    cancel={() => {
                        const sched = schedule;
                        if (sched) {
                            setDate(sched.dateID);
                            setTime(sched.timeID);
                        } else {
                            setDate(0);
                            setTime(0);
                        }
                        setView('quick');
                    }}
                    close={() => { }}
                    availableDates={getDateChoices(availabilities, location)}
                    otherAvailable={getDateChoices(availabilities, 1 - location)}
                    state={date} setState={setDate} />}
                {view.includes('times') &&
                    <Selector options={getTimeChoices(availabilities, location, date)} state={time} setState={setTime} optionStyles={PillOption()} close={() => {
                        setView('quick');
                    }}
                        confirm={(id) => {
                            setSchedule({
                                locID: location,
                                location: availabilities[location].location,
                                dateID: date,
                                date: getDate(availabilities, location, date),
                                timeID: time,
                                time: getLongTime(availabilities, location, date, time)
                            });
                            setTime(id);
                            if (view.startsWith('quick')) setView('quick');
                            else setView('expanded');
                        }}
                        cancel={() => {
                            const sched = schedule;
                            if (sched) {
                                setTime(sched.timeID);
                            } else {
                                setTime(0);
                            }
                        }}
                        wrap
                        noStretch
                        optionsClass='justify-end'
                        selectorClass='justify-end'
                        selectorStyle={{ maxWidth: '28vw' }} />
                }
            </div>}
        </div>
    );
}

export default Scheduler;