import React from 'react';

import Redirect from './Redirect';

import { photoMap3 as photoMap } from '../../functions/PhotoMap';
import Logo from '../../assets/Logo1.svg';
import MenuItemBackground from '../../assets/MenuItemBackground.jpg';

function MenuItem(props) {
    let parsedURL = props.url.split('/');
    return (
        <Redirect to={'/menu' + props.url}>
            <div className='frame center stretch column' style={{ gap: '8rem', padding: '0' }}>
                <div className='frame relative center' style={{ height: '150rem', width: '168rem' }}>
                    <img src={MenuItemBackground} className='frame absolute'
                        style={{ height: '150rem', width: '168rem', borderRadius: '24rem' }}></img>
                    <img src={props.thumbnail || (photoMap.get(props.url) ? photoMap.get(props.url) : Logo)} onError={({ target }) => {
                        target.onerror = null;
                        target.src = Logo;
                    }} className='frame item-image absolute'
                        style={{ height: '140rem', width: '150rem' }}></img>
                </div>
                <div className='frame column center stretch flex-100' style={{ gap: '4rem' }}>
                    <p className='font-14 center bold' style={{ width: '130rem', alignSelf: 'center' }}>{props.name}</p>
                    <p className='font-14 faded-grey center stretch'>{props.price}</p>
                </div>
            </div>
        </Redirect>
    );
}

export default MenuItem;