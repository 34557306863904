import React, { useEffect } from 'react';

function PhotoInput(props) {
    const {
        id, state, setState,
        className, inputStyle,
        title, info
    } = props;

    const [error, setError] = React.useState('');

    useEffect(() => {
        // Load image from localStorage on mount
        const savedImage = localStorage.getItem(`photo_${id}`);
        if (savedImage) {
            setState(savedImage);
        }

        // Cleanup on unmount
        return () => {
            if (state && state.startsWith('blob:')) {
                URL.revokeObjectURL(state);
            }
        };
    }, []);

    useEffect(() => {
        if (error === '') return;
        const timer = setTimeout(() => {
            setError('');
        }, 12000);
        return () => clearTimeout(timer);
    }, [error]);

    const handleChangeImage = e => {
        const file = e.target.files[0];
        if (!file) return;

        let newObjectURL;
        if (state && state.startsWith('blob:')) {
            URL.revokeObjectURL(state);
            newObjectURL = URL.createObjectURL(e.target.files[0]);
            setState(newObjectURL);
        }

        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result;
            try {
                localStorage.setItem(`photo_${id}`, base64String);
                setState(base64String);
            } catch (e) {
                URL.revokeObjectURL(newObjectURL);
                setState(undefined);
                setError('Failed to save image. Please try a smaller image.');
                console.error(e);
            }
        };
        reader.readAsDataURL(file);
    };

    const handleRemoveImage = () => {
        if (state && (state.startsWith('blob:') || state.startsWith('data:'))) {
            URL.revokeObjectURL(state);
        }
        setState(undefined);
        localStorage.removeItem(`photo_${id}`);
    };

    const description = title || info;

    let photo;
    if (!state || state === '') {
        photo = <div className='frame center stretch hover-pointer' style={{ ...inputStyle?.default, aspectRatio: '1' }}>
            <p className='font-20 light-grey'>Click to upload photo</p>
        </div>;
    } else {
        photo = <div className='frame relative center stretch hover-pointer' style={{ ...inputStyle?.selected }}>
            <img src={state} style={{ width: '100%', aspectRatio: '1', objectFit: 'cover' }} />
        </div>;
    }

    return (
        <div className={'frame relative column center stretch ' + className} style={{ gap: '16rem' }}>
            {description &&
                <div className='frame row justify-between stretch'>
                    <p className='font-24 semi-bold'>{title}</p>
                    <p className='font-24 primary semi-bold'>{info}</p>
                </div>
            }
            <div className='frame row center stretch nowrap photo-box hover-pointer' style={{
                ...inputStyle.default,
                position: 'relative'
            }}>
                <label htmlFor={'reference-' + id} className="reference-label input-label hover-pointer frame stretch center">
                    {photo}
                </label>
                <input className='photo-input' style={{ display: 'none' }} id={'reference-' + id} name={'reference-' + id} type='file' accept='image/*' onChange={handleChangeImage} />
                {state !== undefined && state !== '' && <div className='frame center' style={{ backgroundColor: 'var(--lightest-grey)', position: 'absolute', borderRadius: '50%', width: '24rem', height: '24rem', right: '4%', top: '4%' }}
                    onClick={handleRemoveImage}>
                    <p className='font-28'>x</p>
                </div>}
            </div>
            {error &&
                <p className='font-20 primary'>{error}</p>
            }
        </div>
    );
}

export default PhotoInput;